const paginationFunctions = {
  name: 'paginationFunctions',
  props: {},
  data() {
    return {
      pageNum: Number(this.$route.query.page) || 1,
      perPage: Number(this.$route.query.per_page) || 12,
    };
  },
  methods: {
    pageFirst() {
      this.pageNum = 1;
      this.fetchData({ page: this.pageNum });
    },
    pagePrevious() {
      if (this.pageNum > 1) {
        this.pageNum -= 1;
        this.fetchData({ page: this.pageNum });
      }
    },
    toPage(page) {
      this.pageNum = page;
      this.fetchData({ page: this.pageNum });
    },
    pageNext() {
      if (this.pageNum < this.meta.pagination.total_pages) {
        this.pageNum += 1;
        this.fetchData({ page: this.pageNum });
      }
    },
    pageLast() {
      this.pageNum = this.meta.pagination.total_pages;
      this.fetchData({ page: this.pageNum });
    },
    setPerPage(size) {
      this.perPage = size;
      this.pageNum = 1;
      this.fetchData({
        page: this.pageNum,
        per_page: this.perPage,
      });
    },
  },
};

export default paginationFunctions;
