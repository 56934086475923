<template>
  <ol class="breadcrumb">
    <li
      v-for="(crumb, i) in breadcrumbs"
      :key="`breadcrumbs-item-${crumb.slug}`"
      :class="{ 'breadcrumb-item': true, active: isNotLastBreadcrumb(i) }"
    >
      <template v-if="isNotLastBreadcrumb(i)">
        <router-link :to="crumb.path">{{ crumb.label }}</router-link>
      </template>
      <template v-else>
        {{ crumb.label }}
      </template>
    </li>
  </ol>
</template>
<script>
export default {
  name: 'Breadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isNotLastBreadcrumb(currentIndex) {
      return currentIndex + 1 !== this.breadcrumbs.length;
    },
  },
};
</script>
