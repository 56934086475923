<template>
  <div class="is-loading text-center" :class="`text-${color}`">
    <div :class="`spinner-border ${size} ml-auto`" role="status" aria-hidden="true"></div>
    <div v-if="showText" class="d-block mt-2">
      <strong>{{ loadingText }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    size: {
      type: String,
      required: false,
      default: '',
    },
    loadingText: {
      type: String,
      required: false,
      default: 'Loading...',
    },
    showText: {
      type: Boolean,
      required: false,
      default: true,
    },
    color: {
      type: String,
      required: false,
      default: 'secondary',
    },
  },
};
</script>

<style scoped lang="scss">
.app-header {
  + .is-loading {
    margin-top: 1.5rem;
  }
}
</style>
