<template>
  <div class="container container-fluid" v-if="total > 1">
    <div class="row align-items-center">
      <div class="col-6">
        <div class="form-inline">
          <div class="form-group">
            <select
              v-model="perPageInput"
              class="form-control"
              @change="$emit('setPerPage', perPageInput)"
              id="perPage"
            >
              <option v-for="(amount, index) in perPageOptions" :key="index" :value="amount">
                {{ amount }}
              </option>
            </select>
            <label for="perPage" class="ml-2"><small>results per page</small></label>
          </div>
        </div>
      </div>
      <div class="col-6" v-if="totalPages > 1">
        <nav aria-label="Pagination">
          <ul class="pagination justify-content-end">
            <li :class="{ 'page-item': true, disabled: onFirstPage }">
              <a
                v-if="!onFirstPage"
                :href="href(1)"
                :class="{ 'page-link': true }"
                aria-label="First Page"
                @click.prevent="$emit('pageFirst')"
              >
                <span aria-hidden="true"><font-awesome-icon icon="angle-double-left" /></span>
                <span class="sr-only">First Page</span>
              </a>
              <span v-else class="page-link">
                <span aria-hidden="true"><font-awesome-icon icon="angle-double-left" /></span>
                <span class="sr-only">First Page</span>
              </span>
            </li>
            <li :class="{ 'page-item': true, disabled: onFirstPage }">
              <a
                v-if="!onFirstPage"
                :href="href(currentPage - 1)"
                :class="{ 'page-link': true }"
                aria-label="Previous Page"
                @click.prevent="$emit('pagePrevious')"
              >
                <span aria-hidden="true"><font-awesome-icon icon="angle-left" /></span>
                <span class="sr-only">Previous Page</span>
              </a>
              <span v-else class="page-link">
                <span aria-hidden="true"><font-awesome-icon icon="angle-left" /></span>
                <span class="sr-only">Previous Page</span>
              </span>
            </li>
            <!-- pages -->
            <li
              v-for="page in pages"
              :key="page.name"
              :class="{ 'page-item': true, active: page.name === currentPage }"
            >
              <a
                v-if="page.name !== currentPage"
                :href="href(page.name)"
                :class="{ 'page-link': true, disabled: page.disabled }"
                aria-label="Go To Page"
                @click.prevent="$emit('toPage', page.name)"
              >
                {{ page.name }}
              </a>
              <span v-else class="page-link">
                {{ page.name }}
                <span class="sr-only">(current)</span>
              </span>
            </li>
            <!-- pages -->
            <!-- dots -->
            <li v-if="currentPage + maxVisibleButtons - 1 < totalPages" class="page-item disabled">
              <span class="page-link">...</span>
            </li>
            <li :class="{ 'page-item': true, disabled: onLastPage }">
              <a
                v-if="!onLastPage"
                :href="href(currentPage + 1)"
                :class="{ 'page-link': true }"
                aria-label="Next Page"
                @click.prevent="$emit('pageNext')"
              >
                <span aria-hidden="true"><font-awesome-icon icon="angle-right" /></span>
                <span class="sr-only">Next Page</span>
              </a>
              <span v-else class="page-link">
                <span aria-hidden="true"><font-awesome-icon icon="angle-right" /></span>
                <span class="sr-only">Next Page</span>
              </span>
            </li>
            <li :class="{ 'page-item': true, disabled: onLastPage }">
              <a
                v-if="!onLastPage"
                :href="href(totalPages)"
                :class="{ 'page-link': true }"
                aria-label="Last Page"
                @click.prevent="$emit('pageLast')"
              >
                <span aria-hidden="true"><font-awesome-icon icon="angle-double-right" /></span>
                <span class="sr-only">Last Page</span>
              </a>
              <span v-else class="page-link">
                <span aria-hidden="true"><font-awesome-icon icon="angle-right" /></span>
                <span class="sr-only">Last Page</span>
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    totalPages: {
      type: Number,
      required: false,
      default: 1,
    },
    perPage: {
      type: Number,
      required: false,
      default: 12,
    },
    perPageOptions: {
      type: Array,
      required: false,
      default: () => [4, 12, 20, 32, 48],
    },
  },
  data() {
    return {
      model: this.$route.name || '',
      perPageInput: this.perPage,
    };
  },
  computed: {
    onFirstPage() {
      return this.currentPage === 1;
    },
    previousPage() {
      return this.currentPage === 1 ? this.currentPage : this.currentPage - 1;
    },
    nextPage() {
      return this.currentPage === this.totalPages ? this.currentPage : this.currentPage + 1;
    },
    onLastPage() {
      return this.currentPage === this.totalPages;
    },
    startPage() {
      let currentPage = this.currentPage - 1;

      // When on the first page
      if (this.currentPage === 1) {
        currentPage = 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        currentPage = this.totalPages - this.maxVisibleButtons + 1;
      }

      // console.log('Pagination computed startPage() currentPage', currentPage);

      return currentPage;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i += 1
      ) {
        if (i > 0) {
          range.push({
            name: i,
            isDisabled: i === this.currentPage,
          });
        }
      }

      return range;
    },
  },
  methods: {
    getQuery(page) {
      const routeQuery = this.$route.query;
      const query = Object.assign(routeQuery, { page: page });
      // console.log('Pagination methods getQuery() $route.query page', routeQuery, page, query);
      return query;
    },
    href(page) {
      let props = this.$router.resolve({
        name: this.model,
        query: Object.assign(this.$route.query, { page: page }),
      });
      return props.href;
    },
  },
};
</script>

<style scoped lang="scss"></style>
