<template>
  <div class="app-header">
    <div class="row">
      <div class="col">
        <Breadcrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs" />
        <h1>{{ title }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import Breadcrumbs from './Breadcrumbs';

export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  components: { Breadcrumbs },
  computed: {
    breadcrumbs() {
      if (this.routeParts.length === 1) {
        return false;
      }

      // Build the array of labels->routes
      // #TODO `name: part` below throws an error because it maybe a number and not something significant
      const trail = this.routeParts.map((part, i) => {
        // build the path. combine all previous parts
        const url = i === 0 ? part : this.routeParts.slice(0, i + 1).join('/');
        return {
          label: _.upperFirst(part),
          slug: _.kebabCase(part),
          path: {
            path: `/${url}`,
          },
        };
      });

      if (this.title) {
        trail.push({
          label: this.title,
          path: null,
          slug: null,
        });
      }

      return trail;
    },
    routeParts() {
      // console.log('PageHeader routeParts() route', this.$route.path.split('/'));
      // Save the url path components into an array
      let routeParts = this.$route.path.split('/');
      // remove the first empty component (there is always one)
      routeParts.shift();

      return routeParts;
    },
  },
};
</script>
