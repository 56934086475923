<template>
  <div class="container app-content pt-4">
    <!-- Page Header -->
    <PageHeader :title="campaign.title" />

    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" class="mt-4" />
    <template v-else>
      <PaginationResults
        title-suffix="pledges"
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        @searchQuery="searchQuery"
      />
      <PledgesList :pledges="pledges" :striped="true" :hover="true" />
      <Pagination
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        @pageFirst="pageFirst"
        @pageLast="pageLast"
        @pageNext="pageNext"
        @pagePrevious="pagePrevious"
        @toPage="toPage"
        @setPerPage="setPerPage"
      />
    </template>
  </div>
</template>

<script>
import paginationFunctions from 'mixins/paginationFunctions';
import PledgesList from 'components/pledges/PledgesList';
import Error from 'components/Error';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import PaginationResults from 'components/PaginationResults';
import Pagination from 'components/Pagination';

export default {
  name: 'CampaignPledgesIndex',
  mixins: [paginationFunctions],
  components: {
    PledgesList,
    Error,
    Loading,
    PageHeader,
    PaginationResults,
    Pagination,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      error: null,
      isLoading: false,
      pledges: [],
      meta: {},
      pageNum: 1,
      perPage: 12,
    };
  },
  computed: {
    campaign() {
      return this.$store.getters['campaigns/getCurrentCampaign'];
    },
    q() {
      return this.$store.getters['search/getKeywords'];
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  methods: {
    fetchData(params) {
      this.error = null;
      this.isLoading = true;

      const defaultParams = {
        page: this.pageNum,
        per_page: this.perPage,
      };

      const payload = {
        id: this.id,
        params: Object.assign(defaultParams, params || {}),
        saveToStore: true,
      };

      if (this.q) {
        payload.params.search = this.q;

        const numericFields = ['campaign_id', 'promotion_id', 'amount', 'zip'];
        const textFields = [
          'campaign_id',
          'promotion_id',
          'amount',
          'display_as',
          'first',
          'last',
          'city',
          'street',
          'state',
          'zip',
          'email',
        ];

        if (isNaN(this.q)) {
          payload.params.searchFields = textFields.join(';');
        } else {
          payload.params.searchFields = numericFields.join(';');
        }
      }

      this.$store
        .dispatch('campaigns/getPledges', payload)
        .then((response) => {
          // console.log('CampaignsShow methods fetchPledges() then', payload, response.data);
          this.pledges = response.data.data;
          this.meta = response.data.meta;

          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
  },
};
</script>
